/* Navbar */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate-fadeInDown {
  animation: fadeInDown 0.5s ease-out;
}

  /* Home */
  .bg-fixed-cover {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
  }

  .transparent-box {
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5); /* Semi-transparent white border */
    padding: 20px;
    border-radius: 5px;
    color: white; /* White text color */
  }